import "core-js/modules/es.array.push.js";
import BatterTableDataView from "@/components/BatterTableDataView";
import api from "@/api";
import DialogCustomView from "@/components/DialogCustomView";
export default {
  name: "ReportEventView",
  components: {
    BatterTableDataView,
    DialogCustomView
  },
  data() {
    return {
      eventViewVisible: false,
      createVisible: false,
      eventUrl: null
    };
  },
  methods: {
    onDetailData(d) {
      api.getReportEventDetail(d.id).then(url => {
        this.eventUrl = url;
        this.eventViewVisible = true;
      });
    },
    onDetailClick(data) {
      this.$router.push('/shop/detail/' + data.id);
    }
  }
};