import "core-js/modules/es.array.push.js";
import SinglePieChart from "@/components/dashboard/SinglePieChart";
import SimpleVerticalBarChart from "@/components/dashboard/SimpleVerticalBarChart";
import html2pdf from 'html2pdf.js';
import api from "@/api";
import { ElMessage } from "element-plus";
export default {
  name: "ManageReportExportAsPDFView",
  components: {
    SimpleVerticalBarChart,
    SinglePieChart
  },
  data() {
    return {
      pdf: {
        header: '“小河共治”工作通报',
        reportIndex: '一',
        publicInst: '街道综合信息指挥室',
        publicTime: '2023年12月10日',
        dateFrom: null,
        dateEnd: null
      },
      policeEventSinglePieChart: null,
      actionGroups: [],
      shopChecks: [],
      dangerCommunities: null,
      dangerTypes: null,
      dangerCheckGroups: [],
      dangerCheckers: [],
      reportEventStatistic: {
        total: 0,
        lastWeek: 0
      },
      reportEvents: [],
      bikeCleanData: null,
      topThreeWzjyNames: null,
      wzqlData: null,
      shopData: null,
      policeEventThisWeekTotal: null,
      policeEventYoy: null,
      shopCheckData: {},
      exporting: false
    };
  },
  methods: {
    onDateChange(d) {
      this.pdf.dateFrom = d[0];
      this.pdf.dateEnd = d[1];
    },
    async generatePDF() {
      ElMessage.info('请稍等');
      this.exporting = true;
      // 获取要转换的 div 元素
      const element = document.getElementById('pdf');
      // 配置选项
      const options = {
        margin: 10,
        filename: `${this.pdf.header}${this.pdf.publicTime}期.pdf`,
        image: {
          type: 'jpeg',
          quality: 1
        },
        html2canvas: {
          scale: 4
        },
        jsPDF: {
          unit: 'mm',
          format: 'a4',
          orientation: 'portrait'
        }
      };
      // 创建 PDF
      html2pdf().from(element).set(options).save();
      this.exporting = false;
    },
    preview() {
      api.getPoliceEventYoyData({
        dateFrom: this.pdf.dateFrom,
        dateEnd: this.pdf.dateEnd
      }).then(ret => {
        this.policeEventSinglePieChart = ret;
        let total = 0;
        let lastTotal = 0;
        for (let e of ret) {
          total += e.value;
          lastTotal += e.lastWeek;
        }
        this.policeEventThisWeekTotal = total;
        if (lastTotal > 0) {
          if (total > lastTotal) {
            this.policeEventYoy = `同比上升${((total - lastTotal) / lastTotal * 100.0).toFixed(2)}%`;
          } else if (total < lastTotal) {
            this.policeEventYoy = `同比下降${((lastTotal - total) / lastTotal * 100.0).toFixed(2)}%`;
          } else {
            this.policeEventYoy = '与上周持平';
          }
        } else {
          this.policeEventYoy = '上周0起';
        }
      });
      //具体小组应急联动情况
      api.getGroupActingList({
        dateFrom: this.pdf.dateFrom,
        dateEnd: this.pdf.dateEnd
      }).then(ret => {
        this.actionGroups = ret;
      });
      //商铺治理
      api.getProblemShopCheckList({
        dateFrom: this.pdf.dateFrom,
        dateEnd: this.pdf.dateEnd
      }).then(ret => {
        this.shopCheckData = ret;
        this.shopChecks = ret.problemShopList;
        let dc = [];
        for (let k of Object.keys(ret.problemCommunities)) {
          dc.push({
            name: k,
            value: ret.problemCommunities[k]
          });
        }
        this.dangerCommunities = dc;
        let dt = [];
        for (let k of Object.keys(ret.problemCategories)) {
          dt.push({
            name: k,
            value: ret.problemCategories[k]
          });
        }
        this.dangerTypes = dt;
        let gd = [];
        let gidx = 1;
        for (let g of Object.values(ret.checkGroupData)) {
          gd.push({
            idx: gidx++,
            groupGroupName: g.groupName,
            checkerCnt: g.checkerIds.length,
            checkTimes: g.checkTimes,
            problems: g.problemCnt
          });
        }
        this.dangerCheckGroups = gd;
        let cd1 = [];
        let cidx = 1;
        for (let g of Object.values(ret.checkerData)) {
          cd1.push({
            idx: cidx++,
            groupGroupName: g.groupName,
            userName: g.userName,
            checkTimes: g.checkTimes,
            problems: g.problemCnt
          });
        }
        this.dangerCheckers = cd1;
      });
      api.getReportEventStatistic({
        dateFrom: this.pdf.dateFrom,
        dateEnd: this.pdf.dateEnd
      }).then(ret => {
        this.reportEventStatistic = ret;
        let rl = [];
        let tindex = 1;
        for (let t of Object.keys(ret.typeCntMap)) {
          rl.push({
            idx: tindex++,
            item: t,
            cnt: ret.typeCntMap[t]
          });
        }
        this.reportEvents = rl;
        this.bikeCleanData = {
          x: Object.keys(ret.typeStreetMap['单车清理']),
          y: Object.values(ret.typeStreetMap['单车清理'])
        };
        this.wzqlData = {
          x: Object.keys(ret.typeStreetMap['无证劝离']),
          y: Object.values(ret.typeStreetMap['无证劝离'])
        };
        let wzqlRank = Object.keys(ret.typeStreetMap['无证劝离']).map(street => {
          return {
            name: street,
            value: ret.typeStreetMap['无证劝离'][street]
          };
        });
        this.topThreeWzjyNames = wzqlRank.sort((a, b) => b.value - a.value).slice(0, 3).map(obj => obj.name).join(', ');
        this.shopData = Object.keys(ret.typeStreetMap['出店经营']).map(street => {
          return {
            name: street,
            value: ret.typeStreetMap['出店经营'][street]
          };
        });
      });
    },
    init() {
      this.pdf.publicTime = this.getCurrentDate();
      // 获取当前日期
      const today = new Date();
      // 获取一周前的日期
      const lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
      this.pdf.dateRange = [this.formatDate(lastWeek), this.formatDate(today)];
      this.pdf.dateFrom = this.formatDate(lastWeek);
      this.pdf.dateEnd = this.formatDate(today);
      this.preview();
    },
    getCurrentDate() {
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, '0'); // 月份从0开始，需要加1
      const day = String(now.getDate()).padStart(2, '0');
      return `${year}年${month}月${day}日`;
    },
    formatDate(date) {
      let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;
      return [year, month, day].join('-');
    }
  },
  mounted() {
    this.init();
  }
};