import api from "@/api";
import BatterTableDataView from "@/components/BatterTableDataView";
export default {
  name: "CommunitySettingView",
  components: {
    BatterTableDataView
  },
  data() {
    return {
      enableMap: {},
      createVisible: false,
      initQuery: {}
    };
  },
  methods: {
    enableChange(id, val) {
      this.enableMap[id] = val;
      api.updateCommunityEnableState(id, val);
    },
    onFetchData(d) {
      for (let itm of d.list) {
        this.enableMap[itm.id] = itm.enable;
      }
    },
    createCommunity(data) {
      api.createCommunity(data).then(() => {
        this.createVisible = false;
        this.initQuery = {
          'fresh': new Date()
        };
      });
    }
  }
};