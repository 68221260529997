import BatterTableDataView from "@/components/BatterTableDataView";
import DialogView from "@/components/DialogView";
import api from "@/api";
export default {
  name: "InternalMessageView.vue",
  components: {
    DialogView,
    BatterTableDataView
  },
  data() {
    return {
      createVisible: false,
      initQuery: {}
    };
  },
  methods: {
    create(data) {
      api.createInternalMessage(data).then(() => {
        this.createVisible = false;
        this.initQuery = {
          'refresh': new Date()
        };
      });
    }
  }
};