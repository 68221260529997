import "core-js/modules/es.array.push.js";
import BatterTableDataView from "@/components/BatterTableDataView";
import api from "@/api";
import DialogCustomView from "@/components/DialogCustomView";
export default {
  name: "InspectListView",
  components: {
    DialogCustomView,
    BatterTableDataView
  },
  data() {
    return {
      createVisible: false,
      reportViewVisible: false,
      reportUrl: null
    };
  },
  methods: {
    viewReport(reportId) {
      api.getViewReportUrl(reportId).then(url => {
        this.reportUrl = url;
        this.reportViewVisible = true;
      });
    },
    onDetailClick(data) {
      this.$router.push('/shop/detail/' + data.id);
    }
  }
};