import axios from "../utils/http";
import path from "./path"

const stringFormat = (formatted, args) => {
    for (let i = 0; i < args.length; i++) {
        let regexp = new RegExp('\\{' + i + '\\}', 'gi')
        formatted = formatted.replace(regexp, args[i])
    }
    return formatted
}
const api = {
    getOssKeySecret() {
        return axios.get(path.baseUrl + path.getOssKeySecret)
    },
    getQueryForm(formId, query) {
        return axios.get(path.baseUrl + path.queryForm[formId], {params: query})
    },
    get(url, query) {
        if (query) {
            return axios.get(path.baseUrl + url, {params: query})
        }
        return axios.get(path.baseUrl + url)
    },
    post(url, data) {
        return axios.post(path.baseUrl + url, data)
    },
    getDialogForm(dataId, bindId) {
        if (bindId != null) {
            return axios.get(path.baseUrl + stringFormat(path.dialogs[dataId], [bindId]))
        }
        return axios.get(path.baseUrl + path.dialogs[dataId])
    },
    getMenus() {
        return axios.get(path.baseUrl + path.menus)
    },
    getUserInfo(accessToken) {
        console.log('getUserInfo accessToken', accessToken);
        return axios.get(path.baseUrl + path.userInfo)
    },
    getLoginCaptchaToken() {
        return axios.get(path.baseUrl + path.loginCaptchaToken)
    },
    login(data) {
        return axios.post(path.baseUrl + path.login, data)
    },
    logout() {
        return axios.get(path.baseUrl + path.logout)
    },
    getBannerList(query) {
        return axios.get(path.baseUrl + path.getBannerList, {params: query})
    },
    createBanner(data) {
        return axios.post(path.baseUrl + path.createBanner, data);
    },
    updateBanner(data) {
        return axios.post(path.baseUrl + path.updateBanner, data);
    },
    deleteBanner(bannerId) {
        return axios.get(path.baseUrl + stringFormat(path.deleteBanner, [bannerId]))
    },

    updateSystemConfig(data) {
        return axios.post(path.baseUrl + path.updateSystemConfig, data);
    },
    getSystemConfig() {
        return axios.get(path.baseUrl + path.getSystemConfig)
    },
    updateImageValue(data) {
        return axios.post(path.baseUrl + path.updateImageValue, data);
    },
    getNextAuditPost() {
        return axios.get(path.baseUrl + path.getNextAuditPost)
    },
    auditPost(data) {
        return axios.post(path.baseUrl + path.auditPost, data);
    },
    getNextAuditComment() {
        return axios.get(path.baseUrl + path.getNextAuditComment)
    },
    auditComment(data) {
        return axios.post(path.baseUrl + path.auditComment, data);
    },
    getNextAuditUser() {
        return axios.get(path.baseUrl + path.getNextAuditUser)
    },
    auditUser(data) {
        return axios.post(path.baseUrl + path.auditUser, data);
    },
    updateQuestionRepo(data) {
        return axios.post(path.baseUrl + path.updateQuestionRepo, data);
    },
    updateQuestionRepoEnableState(repoId, enable) {
        return axios.get(path.baseUrl + stringFormat(path.updateQuestionRepoEnableState, [repoId, enable]))
    },
    deleteStudyRepo(repoId) {
        return axios.get(path.baseUrl + stringFormat(path.deleteStudyRepo, [repoId]))
    },
    createSpecialSubject(data) {
        return axios.post(path.baseUrl + path.createSpecialSubject, data);
    },
    listSpecialSubjects(query) {
        return axios.get(path.baseUrl + path.listSpecialSubjects, {params: query})
    },
    getShopCreateFormInfo() {
        return axios.get(path.baseUrl + path.getShopCreateFormInfo)
    },
    createInternalMessage(data) {
        return axios.post(path.baseUrl + path.createInternalMessage, data);
    },
    updateOrganizationEnableState(id, val) {
        return axios.get(path.baseUrl + stringFormat(path.updateOrganizationEnableState, [id, val]))
    },
    updateUserEnableState(id, val) {
        return axios.get(path.baseUrl + stringFormat(path.updateUserEnableState, [id, val]))
    },
    updateUserManagerLogin(id, val) {
        return axios.get(path.baseUrl + stringFormat(path.updateUserManagerLogin, [id, val]))
    },

    updateBannerEnableState(id, val) {
        return axios.get(path.baseUrl + stringFormat(path.updateBannerEnableState, [id, val]))
    },
    createShopType(data) {
        return axios.post(path.baseUrl + path.createShopType, data);
    },
    updateShopTypeEnableState(id, val) {
        return axios.get(path.baseUrl + stringFormat(path.updateShopTypeEnableState, [id, val]))
    },
    createShopMatter(data) {
        return axios.post(path.baseUrl + path.createShopMatter, data);
    },
    updateShopMatterEnableState(id, val) {
        return axios.get(path.baseUrl + stringFormat(path.updateShopMatterEnableState, [id, val]))
    },
    updateCommunityEnableState(id, val) {
        return axios.get(path.baseUrl + stringFormat(path.updateCommunityEnableState, [id, val]))
    },
    createCommunity(data) {
        return axios.post(path.baseUrl + path.createCommunity, data);
    },
    createStreet(data) {
        return axios.post(path.baseUrl + path.createStreet, data);
    },
    updateStreetEnableState(id, val) {
        return axios.get(path.baseUrl + stringFormat(path.updateStreetEnableState, [id, val]))
    },
    getShopInfo(shopId) {
        return axios.get(path.baseUrl + stringFormat(path.getShopInfo, [shopId]))
    },
    createOrEditShop(shop) {
        return axios.post(path.baseUrl + path.createOrEditShop, shop);
    },
    getViewReportUrl(reportId) {
        return axios.get(path.baseUrl + stringFormat(path.getViewReportUrl, [reportId]))
    },
    getPoliceEventViewPage(eid) {
        return axios.get(path.baseUrl + stringFormat(path.getPoliceEventViewPage, [eid]))
    },
    createRole(data) {
        return axios.post(path.baseUrl + path.createRole, data);
    },
    getReportEventDetail(id) {
        return axios.get(path.baseUrl + stringFormat(path.getReportEventDetail, [id]))
    },
    getIndexStatisticData() {
        return axios.get(path.baseUrl + path.getIndexStatisticData)
    },
    getIndexEvents() {
        return axios.get(path.baseUrl + path.getIndexEvents)
    },
    getShopDistribution() {
        return axios.get(path.baseUrl + path.getShopDistribution)
    },
    getPoliceEventChart() {
        return axios.get(path.baseUrl + path.getPoliceEventChart)
    },
    getClockData() {
        return axios.get(path.baseUrl + path.getClockData)
    },
    getMapKey() {
        return axios.get(path.baseUrl + path.getMapKey)
    },
    getShopCheckHistory(query) {
        return axios.get(path.baseUrl + path.getShopCheckHistory, {params: query})
    },
    updateChatEnableState(id, val) {
        return axios.get(path.baseUrl + stringFormat(path.updateChatEnableState, [id, val]))
    },
    getGongshuLoginRedirect(url) {
        return axios.get(path.baseUrl + stringFormat(path.getGongshuLoginRedirect, [url,]))
    },
    getUserInfoByCode(code) {
        return axios.get(path.baseUrl + stringFormat(path.getUserInfoByCode, [code,]))
    },
    bindIamByToken(iamId) {
        return axios.get(path.baseUrl + stringFormat(path.bindIamByToken, [iamId,]))
    },
    unbindIAM() {
        return axios.get(path.baseUrl + path.unbindIAM)
    },
    getPoliceEventYoyData(query) {
        return axios.get(path.baseUrl + path.getPoliceEventYoyData, {params: query})
    },
    getGroupActingList(query) {
        return axios.get(path.baseUrl + path.getGroupActingList, {params: query})
    },
    getProblemShopCheckList(query) {
        return axios.get(path.baseUrl + path.getProblemShopCheckList, {params: query})
    },
    getReportEventStatistic(query) {
        return axios.get(path.baseUrl + path.getReportEventStatistic, {params: query})
    }
}

export default api;