import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-5023a830"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "cardContainer"
};
const _hoisted_2 = {
  class: "cardBigNumber"
};
const _hoisted_3 = {
  style: {
    "margin-bottom": "5px",
    "margin-left": "16px"
  },
  class: "colFlex"
};
const _hoisted_4 = {
  key: 0,
  style: {
    "display": "flex"
  }
};
const _hoisted_5 = {
  class: "cardSmallFont"
};
const _hoisted_6 = {
  class: "cardSmallFont"
};
const _hoisted_7 = {
  style: {
    "display": "flex"
  }
};
const _hoisted_8 = {
  class: "cardSmallFont"
};
const _hoisted_9 = {
  class: "cardSmallFont"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_row = _resolveComponent("el-row");
  const _component_CaretTop = _resolveComponent("CaretTop");
  const _component_el_icon = _resolveComponent("el-icon");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_row, null, {
    default: _withCtx(() => [_createElementVNode("span", null, _toDisplayString($props.title), 1)]),
    _: 1
  }), _createVNode(_component_el_row, {
    style: {
      "display": "flex",
      "align-items": "flex-end"
    }
  }, {
    default: _withCtx(() => [_createElementVNode("span", _hoisted_2, _toDisplayString($props.total), 1), _createElementVNode("div", _hoisted_3, [$props.subTitleB ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createElementVNode("span", _hoisted_5, _toDisplayString($props.subTitleB), 1), _createVNode(_component_el_icon, {
      color: "red"
    }, {
      default: _withCtx(() => [_createVNode(_component_CaretTop)]),
      _: 1
    }), _createElementVNode("span", _hoisted_6, _toDisplayString($props.subValue), 1)])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_7, [_createElementVNode("span", _hoisted_8, _toDisplayString($props.yesterdayKey), 1), _createVNode(_component_el_icon, {
      color: "red"
    }, {
      default: _withCtx(() => [_createVNode(_component_CaretTop)]),
      _: 1
    }), _createElementVNode("span", _hoisted_9, _toDisplayString($props.yesterday), 1)])])]),
    _: 1
  })]);
}