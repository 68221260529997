import api from "@/api";
export default {
  name: "ShopCheckHistoryLine",
  props: {
    shopId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      selectedIndex: 0,
      reports: []
    };
  },
  methods: {
    selectReport(rpIndex) {
      this.selectedIndex = rpIndex;
      this.$emit('onSelectReport', this.reports[rpIndex]);
    },
    init() {
      api.getShopCheckHistory({
        shopId: this.shopId,
        page: 0,
        pageSize: 1000
      }).then(ret => {
        this.reports = ret.list;
        if (this.reports && this.reports.length > 0) {
          this.selectReport(0);
          this.$emit('count', this.reports.length);
        } else {
          this.$emit('count', 0);
        }
      });
    }
  },
  mounted() {
    this.init();
  }
};