import elementResizeDetectorMaker from "element-resize-detector";
import * as echarts from "echarts";
const erd = elementResizeDetectorMaker();
export default {
  name: "VerticalBarChart",
  data() {
    return {};
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return {
          x: [],
          y: []
        };
      }
    }
  },
  watch: {
    barData() {
      this.$nextTick(() => this.renderChart());
    }
  },
  mounted() {
    erd.listenTo(this.$el, this.resize.bind(this));
    this.renderChart();
  },
  unmounted() {},
  methods: {
    resize() {
      // this.$nextTick(() => this.renderChart());
    },
    renderChart() {
      // if (!this.barData.data) {
      //   return
      // }
      let option = {
        grid: {
          bottom: '0',
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          data: ['商铺数量']
        },
        toolbox: {
          show: true,
          orient: 'vertical',
          left: 'right',
          top: 'center'
        },
        xAxis: [{
          type: 'category',
          axisTick: {
            show: false
          },
          data: this.data.x.map(s => s.streetName),
          axisLabel: {
            interval: 0,
            rotate: 30
          }
        }],
        yAxis: [{
          type: 'value'
        }],
        series: [{
          name: '商铺数量',
          type: 'bar',
          barGap: 0,
          emphasis: {
            focus: 'series'
          },
          data: this.data.y,
          itemStyle: {
            color: '#5087EC'
          }
        }]
      };
      let bar = document.getElementById("canvas");
      let exist = echarts.getInstanceByDom(bar);
      if (exist) {
        console.log('canvas', '销毁已存在的');
        exist.dispose();
      }
      let chart = echarts.init(bar);
      chart.setOption(option);
    }
  }
};