import "core-js/modules/es.array.push.js";
import api from "@/api";
import { ElMessage } from 'element-plus';
export default {
  name: "DialogView",
  data() {
    return {
      dialogVisible: false,
      detail: {},
      form: {},
      eventActions: {},
      dataSource: {},
      dataSourceMap: {},
      clearKeys: {},
      ossClient: null,
      ossInitState: false,
      uploading: false,
      ossDomain: null,
      ossFolder: null
    };
  },
  props: {
    visibleControl: {
      type: Boolean,
      default: false
    },
    dataId: {
      type: Number,
      default: 0
    },
    bindId: {
      type: Number,
      default: -1
    },
    formOverrideData: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  watch: {
    visibleControl(b) {
      this.dialogVisible = b;
      if (b) {
        this.render();
      }
    },
    formOverrideData(o) {
      for (let k in o) {
        this.form[k] = o[k];
      }
    }
  },
  methods: {
    fnLazyLoad(itemKey, source, node, resolve) {
      let query = {};
      if (node.level > 0) {
        query[itemKey] = node.value;
      }
      api.get(source, query).then(ret => {
        resolve(ret);
      });
    },
    initOssClint() {
      if (this.ossInitState) {
        console.log('OSS已经完成初始化!');
        return;
      }
      this.ossInitState = true;
      let OSS = require('ali-oss');
      api.getOssKeySecret().then(cfg => {
        this.ossClient = new OSS({
          region: cfg.region,
          accessKeyId: cfg.accessKeyId,
          accessKeySecret: cfg.accessKeySecret,
          stsToken: cfg.securityToken,
          bucket: cfg.bucket,
          endpoint: cfg.endpoint,
          secure: true
        });
        this.ossDomain = cfg.domain;
        this.ossFolder = cfg.folder;
      });
    },
    beforeImageUpload(file) {
      let isImage = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/gif' || file.type === 'video/mp4';
      // let isLt2M = file.size / 1024 / 1024 < 2;
      if (!isImage) {
        ElMessage.error('仅支持jpeg/jpg/png/gif/mp4格式');
      }
      // if (!isLt2M) {
      //   ElMessage.error('文件大小不得超过2MB');
      // }
      return isImage;
    },
    handleImageUploadSuccess(formKey, url, r) {
      if (this.ossDomain) {
        url = this.ossDomain + '/' + r.name;
      }
      this.uploading = false;
      if (this.form[formKey]) {
        this.form[formKey].push(url);
      } else {
        this.form[formKey] = [url];
      }
    },
    handleUploadImageRemove(formKey, d) {
      let fileUrl = d.response ? d.response.url : d.url;
      let filtered = [];
      for (let v of this.form[formKey]) {
        if (v !== fileUrl) {
          filtered.push(v);
        }
      }
      this.form[formKey] = filtered;
    },
    guid() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0,
          v = c === 'x' ? r : r & 0x3 | 0x8;
        return v.toString(16);
      });
    },
    uploadRequest(options) {
      try {
        this.uploading = true;
        let file = options.file; // 拿到 file
        let fileType = file.name.substr(file.name.lastIndexOf('.'));
        let fileName = (this.ossFolder ? this.ossFolder + '/' : 'user-upload/') + this.guid() + fileType;
        // 上传文件,这里是上传到OSS的 uploads文件夹下
        this.ossClient.put(fileName, file).then(res => {
          if (res.res.statusCode === 200) {
            options.onSuccess(res);
          } else {
            options.onError("上传失败");
          }
        });
      } catch (e) {
        options.onError("上传失败");
      }
    },
    queryDataSource(dataKey) {
      if (dataKey && this.clearKeys[dataKey]) {
        //需要清除其他key的情形
        for (let cl of this.clearKeys[dataKey]) {
          this.form[cl] = null;
        }
        this.$emit('formDataChange', {
          'key': dataKey,
          'value': this.form[dataKey]
        });
      }
      for (let k of Object.keys(this.dataSourceMap)) {
        if (dataKey && dataKey === k) {
          //当前key不更新
          continue;
        }
        let query = {};
        for (let k of Object.keys(this.form)) {
          if (this.form[k] != null && !(this.form[k] instanceof Array)) {
            query[k] = this.form[k];
          }
        }
        api.get(this.dataSourceMap[k], query).then(ret => {
          this.dataSource[k] = ret;
        });
      }
    },
    onDialogClose() {
      this.$emit('onDialogClose');
      this.$emit('close');
      this.detail = {};
      this.form = {};
    },
    onButtonClick(action) {
      if (action === 'submit') {
        this.$emit('submit', this.form);
      } else {
        this.onDialogClose();
      }
    },
    render() {
      if (this.dataId <= 0 || !this.visibleControl) {
        return;
      }
      api.getDialogForm(this.dataId, this.bindId).then(ret => {
        this.detail = ret;
        for (let part of ret.details) {
          for (let d of part.items) {
            if (d['type'] === 'cascader' || d['type'] === 'select' || d['type'] === 'multiSelect') {
              this.form[d['name']] = d['selected'];
            } else if (d['type'] === 'tree') {
              this.form[d['name']] = d['selected'];
              this.eventActions[d['name']] = (checkedNodes, checkedKeys) => {
                this.form[d['name']] = checkedKeys.checkedKeys;
              };
            } else if (d['type'] === 'uploadImage') {
              this.initOssClint();
              if (d['value']) {
                //有上传列表预览
                let fileList = [];
                let files = [];
                for (let g of d['value']) {
                  fileList.push({
                    name: g.id,
                    url: g.imageUrl
                  });
                  files.push(g.imageUrl);
                }
                this.form[d['name']] = files;
                this.form[d['name'] + '_init'] = fileList;
              }
            } else {
              this.form[d['name']] = d['value'];
            }
            if (d['dataSource']) {
              this.dataSourceMap[d.name] = d.dataSource;
            }
            if (d['clearKeys']) {
              this.clearKeys[d.name] = d.clearKeys;
            }
          }
        }
        this.queryDataSource();
      });
    }
  },
  mounted() {
    this.render();
  }
};