import DialogCustomView from "@/components/DialogCustomView";
export default {
  name: "ShopInfoTable",
  components: {
    DialogCustomView
  },
  props: {
    shop: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      preview: false
    };
  },
  methods: {
    previewImage() {}
  }
};