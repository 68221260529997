export default {
  name: "DialogCustomView",
  data() {
    return {
      dialogVisible: false
    };
  },
  props: {
    visibleControl: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: '40%'
    },
    title: {
      type: String,
      default: 'TITLE'
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    visibleControl(b) {
      this.dialogVisible = b;
    }
  },
  methods: {
    onDialogClose() {
      this.$emit('onDialogClose');
      this.$emit('close');
    }
  }
};