import BatterTableDataView from "@/components/BatterTableDataView";
export default {
  name: "DutyCheckView",
  components: {
    BatterTableDataView
  },
  methods: {
    previewImages(imgs) {
      if (!imgs) {
        return;
      }
    },
    init() {}
  },
  mounted() {
    this.init();
  }
};