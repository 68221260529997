import api from "@/api";
import BatterTableDataView from "@/components/BatterTableDataView";
export default {
  name: "ChatListView",
  components: {
    BatterTableDataView
  },
  methods: {
    onFetchData(d) {
      for (let itm of d.list) {
        this.enableMap[itm.id] = itm.id > 0 ? itm.enable : true;
      }
    },
    enableChange(id, val) {
      this.enableMap[id] = val;
      api.updateChatEnableState(id, val);
    }
  },
  data() {
    return {
      enableMap: {},
      statisticsMap: {},
      loginMap: {},
      initQuery: {
        userId: this.$route.query.userId
      }
    };
  }
};