import ShopCreateForm from "@/components/shop/ShopCreateForm";
import ShopInfoTable from "@/components/shop/ShopInfoTable";
import ShopCheckHistoryLine from "@/components/shop/ShopCheckHistoryLine";
import ShopCheckDetail from "@/components/shop/ShopCheckDetail";
import api from "@/api";
export default {
  name: "ShopDetailView",
  components: {
    ShopCheckDetail,
    ShopCheckHistoryLine,
    ShopInfoTable,
    ShopCreateForm
  },
  data() {
    return {
      drawer: false,
      shopId: this.$route.params.shopId,
      shop: {},
      selectReport: null,
      reportCount: 0
    };
  },
  methods: {
    onSelectReport(report) {
      this.selectReport = report;
    },
    init() {
      api.getShopInfo(this.shopId).then(ret => {
        this.shop = ret;
      });
    },
    onSaveEdit(shop) {
      api.createOrEditShop(shop).then(() => {
        this.drawer = false;
        this.init();
      });
    }
  },
  mounted() {
    this.init();
    console.log('shopId', this.$route.params.shopId);
  }
};