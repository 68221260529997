export default {
  name: "ShopCheckDetail",
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  watch: {
    data() {
      this.init();
    }
  },
  data() {
    return {
      matters: []
    };
  },
  methods: {
    init() {
      this.matters = JSON.parse(this.data.reportData);
      for (let m of this.matters) {
        m.detail = JSON.parse(m.detail);
        m.hiddenDangers = JSON.parse(m.hiddenDangers);
      }
      // console.log('matters', this.matters)
    }
  }
};