import * as echarts from 'echarts';
export default {
  name: "SinglePieChart",
  props: {
    id: {
      type: String,
      default: "chart"
    },
    pieData: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  watch: {
    pieData() {
      this.renderChart();
    }
  },
  methods: {
    renderChart() {
      if (!this.pieData) {
        return;
      }
      const option = {
        legend: {
          bottom: '5%',
          left: 'center'
        },
        series: [{
          type: 'pie',
          radius: '60%',
          data: this.pieData,
          labelLine: {
            show: false
          },
          label: {
            show: true,
            position: 'inside',
            formatter: '{b}: {d}%'
          }
        }]
      };
      let pie = document.getElementById(this.id);
      let exist = echarts.getInstanceByDom(pie);
      if (exist) {
        console.log(this.id, '销毁已存在的');
        exist.dispose();
      }
      let chart = echarts.init(pie);
      chart.setOption(option);
    }
  },
  mounted() {
    this.renderChart();
  }
};