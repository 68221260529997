import "core-js/modules/es.array.push.js";
import BatterTableDataView from "@/components/BatterTableDataView";
import DialogCustomView from "@/components/DialogCustomView";
import ShopCreateForm from "@/components/shop/ShopCreateForm";
import api from "@/api";
export default {
  name: "ShopListView",
  components: {
    ShopCreateForm,
    DialogCustomView,
    BatterTableDataView
  },
  data() {
    return {
      createVisible: false
    };
  },
  methods: {
    createShop(s) {
      api.createOrEditShop(s).then(() => {
        this.createVisible = false;
        this.$refs.shopList.fetchData();
      });
    },
    onDetailClick(data) {
      this.$router.push('/shop/detail/' + data.id);
    }
  }
};