import "core-js/modules/es.array.push.js";
import BatterTableDataView from "@/components/BatterTableDataView";
import DialogCustomView from "@/components/DialogCustomView";
import LocationPicker from "@/components/dashboard/LocationPicker";
export default {
  name: "ClockInListView",
  components: {
    LocationPicker,
    DialogCustomView,
    BatterTableDataView
  },
  data() {
    return {
      createVisible: false,
      mapVisible: false,
      longitude: null,
      latitude: null
    };
  },
  methods: {
    openMap(data) {
      this.longitude = data.longitude;
      this.latitude = data.latitude;
      this.mapVisible = true;
    },
    onDetailClick(data) {
      this.$router.push('/shop/detail/' + data.id);
    }
  }
};