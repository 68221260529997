import api from "@/api";
import OssUploader from "@/components/OssUploader";
export default {
  name: "ShopCreateForm",
  components: {
    OssUploader
  },
  props: {
    shopId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      defaults: {
        bg: 'https://cdn.waityou.online/d93ec88b-b093-b217-4284-07c8d9386942.png',
        uploader: 'https://cdn.waityou.online/2c705c1f-c14c-9eed-5bf7-ebac8d7d3d6d.png'
      },
      shop: {},
      shopTypes: [],
      communities: [],
      streets: [],
      info: {}
    };
  },
  methods: {
    save() {
      this.$emit('save', this.shop);
    },
    init() {
      api.getShopCreateFormInfo().then(ret => {
        this.streets = ret.streets;
        this.communities = ret.communities;
        this.shopTypes = ret.shopTypes;
      });
      if (this.shopId < 1) {
        return;
      }
      api.getShopInfo(this.shopId).then(ret => {
        this.shop = ret;
        this.shop.shopType = ret.shopTypeId;
        this.shop.state = ret.state === 'RUNNING' ? '0' : '1';
      });
    }
  },
  mounted() {
    this.init();
  }
};