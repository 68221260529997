import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_upload = _resolveComponent("el-upload");
  return _openBlock(), _createBlock(_component_el_upload, {
    "auto-upload": true,
    limit: 1,
    "before-upload": $options.beforeUpload,
    "http-request": $options.uploadRequest,
    "show-file-list": false,
    "on-success": r => $options.handleImageUploadSuccess(r.url, r)
  }, {
    trigger: _withCtx(() => [_renderSlot(_ctx.$slots, "default", {}, undefined, true)]),
    _: 3
  }, 8, ["before-upload", "http-request", "on-success"]);
}