export default {
  name: "DashboardInfoCard",
  props: {
    subTitleB: {
      type: String,
      default: null
    },
    subValue: {
      type: Number,
      default: 0
    },
    yesterdayKey: {
      type: String,
      default: '昨日新增'
    },
    total: {
      type: Number,
      default: 0
    },
    yesterday: {
      type: Number,
      default: 0
    },
    title: {
      type: String,
      default: '-'
    }
  }
};