import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["id"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    id: $props.id,
    style: {
      "width": "100%",
      "height": "100%"
    },
    ref: "canvas"
  }, null, 8, _hoisted_1);
}