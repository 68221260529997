import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-601b2c29"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["id"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_row = _resolveComponent("el-row");
  return _openBlock(), _createBlock(_component_el_row, {
    class: "bothCenter"
  }, {
    default: _withCtx(() => [_createElementVNode("div", {
      id: $props.id,
      style: {
        "width": "700px",
        "height": "400px"
      },
      ref: "canvas"
    }, null, 8, _hoisted_1)]),
    _: 1
  });
}