import {createRouter, createWebHashHistory} from 'vue-router'
import LoginView from '../views/LoginView.vue'
import HomeView from "@/views/HomeView";
import DashboardView from "@/views/dashboard/DashboardView";
import UserListView from "@/views/user/UserListView";
import ShopListView from "@/views/shop/ShopListView";
import ShopDetailView from "@/views/shop/ShopDetailView";
import InspectListView from "@/views/inspect/InspectListView";
import PoliceEventListView from "@/views/police/PoliceEventListView";
import ReportEventView from "@/views/report/ReportEventView";
import ClockInListView from "@/views/clock/ClockInListView";
import InternalMessageView from "@/views/message/InternalMessageView";
import RoleListView from "@/views/user/RoleListView";
import BannerSettingView from "@/views/system/BannerSettingView";
import ShopTypeSettingView from "@/views/system/ShopTypeSettingView";
import ShopMatterSettingView from "@/views/system/ShopMatterSettingView";
import CommunitySettingView from "@/views/system/CommunitySettingView";
import StreetSettingView from "@/views/system/StreetSettingView";
import OrganizationListView from "@/views/user/OrganizationListView";
import ChatListView from "@/views/chat/ChatListView";
import DutyCheckView from "@/views/check/DutyCheckView";
import ManageReportExportAsPDFView from "@/views/report/ManageReportExportAsPDFView";

const routes = [
    {
        path: "/",
        name: "home",
        component: HomeView,
        redirect: "dashboard",
        children: [
            {
                path: "/",
                component: DashboardView
            },
            {
                path: "/dashboard",
                component: DashboardView
            },
            {
                path: '/banner/list',
                component: BannerSettingView
            },
            {
                path: '/user/list',
                component: UserListView
            },
            {
                path: '/shop/list',
                component: ShopListView
            },
            {
                path: '/inspect/list',
                component: InspectListView
            },
            {
                path: '/shop/detail/:shopId',
                component: ShopDetailView
            },
            {
                path: '/police/event/list',
                component: PoliceEventListView
            },
            {
                path: '/event/report/list',
                component: ReportEventView
            },
            {
                path: '/clock/list',
                component: ClockInListView
            },
            {
                path: '/message/list',
                component: InternalMessageView
            },
            {
                path: '/role/list',
                component: RoleListView
            },
            {
                path: '/shop/types',
                component: ShopTypeSettingView
            },
            {
                path: '/shop/matters',
                component: ShopMatterSettingView
            },
            {
                path: '/community/list',
                component: CommunitySettingView
            },
            {
                path: '/street/list',
                component: StreetSettingView
            },
            {
                path: '/organization/list',
                component: OrganizationListView
            },
            {
                path: '/chat/list',
                component: ChatListView
            },
            {
                path: '/duty/check',
                component: DutyCheckView
            }
        ],
        meta: {
            auth: true
        }
    },
    {
        path: '/manage/report/export',
        component: ManageReportExportAsPDFView,
    },
    {
        path: '/login',
        name: 'login',
        component: LoginView,
        meta: {
            auth: false
        }
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router
