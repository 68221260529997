const base = {
    baseUrl: "",
    menus: "/api/manager/menu/menuList",
    userInfo: "/api/manager/info/my",
    loginCaptchaToken: "/login/captchaToken",
    login: "/login/accountLogin",
    logout: "/api/user/logout?channel=0",

    createBanner: "/api/manager/setting/mobile/createBanner",

    getOssKeySecret: "/api/manager/oss/getOssKeySecret",
    updateSystemConfig: '/api/manager/config/update',
    getSystemConfig: '/api/manager/config/list',
    updateImageValue: '/api/manager/config/updateImageValue',
    getNextAuditPost: '/api/manager/audit/getNextAuditPost',
    auditPost: '/api/manager/audit/auditPost',
    auditComment: '/api/manager/audit/auditComment',
    auditUser: '/api/manager/audit/auditUser',
    getNextAuditComment: '/api/manager/audit/getNextAuditComment',
    getNextAuditUser: '/api/manager/audit/getNextAuditUser',
    updateQuestionRepo: '/api/manager/study/repo/update',
    updateQuestionRepoEnableState: '/api/manager/study/repo/updateEnable?repoId={0}&enable={1}',
    deleteStudyRepo: '/api/manager/study/repo/delete?repoId={0}',
    createSpecialSubject: '/api/manager/special/subject/create',
    listSpecialSubjects: '/api/manager/special/subject/list',
    getShopCreateFormInfo: '/api/manager/shop/getShopCreateFormInfo',
    createInternalMessage: '/api/manager/message/create',
    updateOrganizationEnableState: '/api/manager/organization/updateEnableState?id={0}&value={1}',
    updateUserEnableState: '/api/manager/user/updateEnableState?id={0}&value={1}',
    updateUserManagerLogin: '/api/manager/user/updateUserManagerLogin?id={0}&value={1}',
    updateBannerEnableState: '/api/manager/setting/mobile/updateBannerEnableState?id={0}&value={1}',
    createShopType: '/api/manager/shop/type/create',
    updateShopTypeEnableState: '/api/manager/shop/type/updateEnableState?id={0}&value={1}',
    createShopMatter: '/api/manager/shop/matter/create',
    updateShopMatterEnableState: '/api/manager/shop/matter/updateEnableState?id={0}&value={1}',
    updateCommunityEnableState: '/api/manager/community/updateEnableState?id={0}&value={1}',
    createCommunity: '/api/manager/community/create',
    createStreet: '/api/manager/street/create',
    updateStreetEnableState: '/api/manager/street/updateEnableState?id={0}&value={1}',
    getShopInfo: '/api/manager/shop/info?shopId={0}',
    createOrEditShop: '/api/manager/shop/createOrUpdate',
    getViewReportUrl: '/api/manager/inspection/getReportViewPage?reportId={0}',
    getPoliceEventViewPage: '/api/manager/police/getEventViewPage?eventId={0}',
    createRole: '/api/manager/role/create',
    getReportEventDetail: '/api/manager/report/getReportViewPage?reportId={0}',
    getIndexStatisticData: '/api/manager/statistic/index',
    getIndexEvents: '/api/manager/statistic/indexEvents',
    getShopDistribution: '/api/manager/statistic/getShopDistribution',
    getPoliceEventChart: '/api/manager/statistic/getPoliceEventChart',
    getClockData: '/api/manager/statistic/getClockData',
    getMapKey: '/api/manager/setting/amap/getMapKey',
    getShopCheckHistory: '/api/manager/shop/check/getShopCheckHistory',
    updateChatEnableState: '/api/manager/chat/updateChatEnableState?id={0}&val={1}',

    getGongshuLoginRedirect: '/login/gongshu/login?url={0}',
    getUserInfoByCode: '/login/gongshu/getUserInfoByCode?code={0}',
    bindIamByToken: '/api/manager/user/bindIamByToken?token={0}',
    unbindIAM: '/api/manager/user/unbindIam',

    getPoliceEventYoyData: '/api/manager/statistic/getPoliceEventYoyData',
    getGroupActingList: '/api/manager/statistic/getGroupActingList',
    getProblemShopCheckList: '/api/manager/statistic/getProblemShopCheckList',
    getReportEventStatistic: '/api/manager/statistic/getReportEventStatistic',

    queryForm: {
        1: '/api/manager/shop/getQueryForm',
        2: '/api/manager/inspection/getQueryForm',
        3: '/api/manager/police/getQueryForm',
        4: '/api/manager/report/getQueryForm',
        5: '/api/manager/clock/getQueryForm',
        7: '/api/manager/user/getQueryForm',
        8: '/api/manager/organization/getQueryForm',
        9: '/api/manager/chat/getQueryForm',
        10: '/api/manager/duty/check/getQueryForm',
    },
    dialogs: {
        1: '/api/manager/message/getCreateForm',
        7: '/api/manager/role/getCreateForm',
        8: '/api/manager/role/getEditForm?roleId={0}',
        15: '/api/manager/setting/mobile/getBannerCreateForm',
        16: '/api/manager/setting/mobile/getBannerEditForm?id={0}',
        21: '/api/manager/user/getCreateForm',
        22: '/api/manager/user/getEditForm?userId={0}',
        23: '/api/manager/user/detail?userId={0}',
        24: '/api/manager/shop/type/getCreateForm',
        25: '/api/manager/shop/type/getUpdateForm?id={0}',
        26: '/api/manager/shop/matter/getUpdateForm?id={0}',
        27: '/api/manager/shop/matter/getCreateForm',
        28: '/api/manager/community/getUpdateForm?id={0}',
        29: '/api/manager/community/getCreateForm',
        30: '/api/manager/street/getUpdateForm?id={0}',
        31: '/api/manager/street/getCreateForm',
        32: '/api/manager/organization/getCreateForm',
        33: '/api/manager/organization/getUpdateForm?id={0}',
        34: '/api/manager/police/getEditForm?id={0}',
        35: '/api/manager/chat/getCreateForm',
        36: '/api/manager/chat/getEditForm?id={0}',
        37: '/api/manager/duty/check/detail?id={0}'
    },
    table: {
        1: {
            queryForm: 1,
            list: '/api/manager/shop/list',
        },
        2: {
            queryForm: 2,
            list: '/api/manager/inspection/list',
            listMethod:'post',
        },
        3: {
            queryForm: 3,
            list: '/api/manager/police/list',
            updateDialog: 34,
            updateKey: 'id',
            update: '/api/manager/police/update',
            deleteKey: 'id',
            delete: '/api/manager/police/delete'
        },
        4: {
            queryForm: 4,
            list: '/api/manager/report/list',
            delete: '/api/manager/report/delete',
            deleteKey: 'id'
        },
        5: {
            queryForm: 5,
            list: '/api/manager/clock/list',
        },
        6: {
            list: '/api/manager/message/list',
            delete: '/api/manager/message/delete',
            deleteKey: 'id'
        },
        9: {
            list: '/api/manager/role/list',
            updateDialog: 8,
            updateKey: 'roleId',
            update: '/api/manager/role/update',
            delete: '/api/manager/role/delete',
            deleteKey: 'roleId',
        },
        14: {
            list: '/api/manager/setting/mobile/bannerList',
            updateDialog: 16,
            update: '/api/manager/setting/mobile/updateBanner',
            updateKey: 'id',
            delete: '/api/manager/setting/mobile/deleteBanner',
            deleteKey: 'id'
        },
        18: {
            queryForm: 7,
            list: '/api/manager/user/list',
            createDialog: 21,
            create: '/api/manager/user/create',
            updateDialog: 22,
            update: '/api/manager/user/update',
            updateKey: 'id',
            delete: '/api/manager/user/delete',
            deleteKey: 'id',
            detailDialog: 23,
            detailKey: 'userId'
        },
        19: {
            list: '/api/manager/shop/type/list',
            updateDialog: 25,
            update: '/api/manager/shop/type/update',
            updateKey: 'id',
            delete: '/api/manager/shop/type/delete',
            deleteKey: 'id',
        },
        20: {
            list: '/api/manager/shop/matter/list',
            updateDialog: 26,
            update: '/api/manager/shop/matter/update',
            updateKey: 'id',
            delete: '/api/manager/shop/matter/delete',
            deleteKey: 'id',
        },
        21: {
            list: '/api/manager/community/list',
            updateDialog: 28,
            update: '/api/manager/community/update',
            updateKey: 'id',
            delete: '/api/manager/community/delete',
            deleteKey: 'id',
        },
        22: {
            list: '/api/manager/street/list',
            updateDialog: 30,
            update: '/api/manager/street/update',
            updateKey: 'id',
            delete: '/api/manager/street/delete',
            deleteKey: 'id',
        },
        23: {
            queryForm: 8,
            list: '/api/manager/organization/list',
            createDialog: 32,
            create: '/api/manager/organization/create',
            updateDialog: 33,
            update: '/api/manager/organization/update',
            updateKey: 'id',
            delete: '/api/manager/organization/delete',
            deleteKey: 'id',
        },
        24: {
            queryForm: 9,
            list: '/api/manager/chat/list',
            createDialog: 35,
            create: '/api/manager/chat/create',
            updateDialog: 36,
            update: '/api/manager/chat/update',
            updateKey: 'id',
            delete: '/api/manager/chat/delete',
            deleteKey: 'id',
        },
        25: {
            queryForm: 10,
            listMethod: 'post',
            list: '/api/manager/duty/check/list',
            export: '/api/manager/duty/check/export',
            detailDialog: 37,
            detailKey: 'id',
            delete: '/api/manager/chat/delete',
            deleteKey: 'id',
        }
    }

}

export default base;