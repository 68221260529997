import SvgIcon from "@/components/SvgIcon";
import DashboardInfoCard from "@/components/dashboard/DashboardInfoCard";
import IndexEmActivity from "@/components/dashboard/IndexEmActivity";
import IndexInfoActivity from "@/components/dashboard/IndexInfoActivity";
import IndexWarnActivity from "@/components/dashboard/IndexWarnActivity";
import VerticalBarChart from "@/components/dashboard/VerticalBarChart";
import LineChart from "@/components/dashboard/LineChart";
import ClockRecord from "@/components/dashboard/ClockRecord";
import api from "@/api";
export default {
  name: "DashboardView",
  components: {
    ClockRecord,
    VerticalBarChart,
    DashboardInfoCard,
    SvgIcon,
    LineChart,
    IndexEmActivity,
    IndexInfoActivity,
    IndexWarnActivity
  },
  data() {
    return {
      activeTab: "first",
      baseData: {},
      indexEvents: [],
      shopDistribution: null,
      policeEvents: null,
      clocks: []
    };
  },
  methods: {
    init() {
      api.getIndexStatisticData().then(ret => {
        this.baseData = ret;
      });
      api.getIndexEvents().then(ret => {
        if (!ret.list || ret.list.length < 1) {
          return;
        }
        this.indexEvents = ret.list;
      });
      api.getShopDistribution().then(ret => {
        this.shopDistribution = ret;
      });
      api.getPoliceEventChart().then(ret => {
        this.policeEvents = ret;
      });
      api.getClockData().then(ret => {
        this.clocks = ret;
      });
    }
  },
  mounted() {
    this.init();
  }
};