import api from "@/api";
export default {
  name: "UserListView",
  methods: {
    onFetchData(d) {
      for (let itm of d.list) {
        this.enableMap[itm.id] = itm.accountState == 'NORMAL';
        this.loginMap[itm.id] = itm.enableManagerLogin;
      }
    },
    enableChange(id, val) {
      this.enableMap[id] = val;
      api.updateUserEnableState(id, val);
    },
    enableManagerLoginChange(id, val) {
      this.loginMap[id] = val;
      api.updateUserManagerLogin(id, val);
    }
  },
  data() {
    return {
      enableMap: {},
      statisticsMap: {},
      loginMap: {},
      initQuery: {
        userId: this.$route.query.userId
      }
    };
  }
};