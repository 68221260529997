import * as echarts from 'echarts';
export default {
  name: "LineChart",
  props: {
    id: {
      type: String,
      default: "chart"
    },
    data: {
      type: Object,
      default: () => {
        return {
          legend: [],
          series: [],
          date: []
        };
      }
    }
  },
  methods: {
    renderChart() {
      // if(!this.lineData.data){
      //   return
      // }
      const option = {
        "tooltip": {
          "trigger": "axis"
        },
        "legend": {
          data: this.data.legend.map(l => l.typeName)
        },
        "grid": {
          "left": "3%",
          "right": "4%",
          "bottom": "3%",
          "containLabel": true
        },
        "xAxis": {
          "type": "category",
          "boundaryGap": false,
          "data": this.data.date
        },
        "yAxis": {
          "type": "value",
          minInterval: 1
        },
        "series": this.data.series
      };
      let pie = document.getElementById(this.id);
      let exist = echarts.getInstanceByDom(pie);
      if (exist) {
        console.log(this.id, '销毁已存在的');
        exist.dispose();
      }
      let chart = echarts.init(pie);
      chart.setOption(option);
    }
  },
  mounted() {
    this.renderChart();
  }
};