import AMapLoader from '@amap/amap-jsapi-loader';
import { shallowRef } from '@vue/reactivity';
import api from '@/api';
export default {
  name: "LocationPicker",
  setup() {
    const map = shallowRef(null);
    return {
      map
    };
  },
  props: {
    longitude: {
      type: Number,
      default: 0
    },
    latitude: {
      type: Number,
      default: 0
    },
    height: {
      type: String,
      default: '550px'
    },
    zoom: {
      type: Number,
      default: 16
    }
  },
  computed: {
    getVuexLocation() {
      return this.$store.state.locationPicker;
    }
  },
  watch: {
    getVuexLocation() {
      if (this.map) {
        this.map.setCenter(this.$store.state.locationPicker);
      }
    }
  },
  mounted() {
    this.renderPage();
  },
  data() {
    return {
      amapKey: "",
      locationMarker: null
    };
  },
  methods: {
    renderPage() {
      api.getMapKey().then(ret => {
        this.amapKey = ret;
        this.initMap();
      });
    },
    initMap() {
      AMapLoader.load({
        key: this.amapKey,
        version: "2.0",
        plugins: ['']
      }).then(AMap => {
        this.map = new AMap.Map("amapContainer", {
          //设置地图容器id
          viewMode: "2D",
          zoom: this.zoom,
          center: [this.longitude, this.latitude]
        });
        this.map.on('click', this.onMapClick);
        this.map.add(this.generateMark(AMap));
      }).catch(e => {
        console.log(e);
      });
    },
    generateMark(AMap) {
      let cfg = {
        position: new AMap.LngLat(this.longitude, this.latitude),
        clickable: true
      };
      cfg.label = {
        content: this.longitude + ',' + this.latitude,
        direction: 'top'
      };
      this.locationMarker = new AMap.Marker(cfg);
      return this.locationMarker;
    },
    onMapClick(event) {
      let newLocation = [event.lnglat.lng, event.lnglat.lat];
      this.locationMarker.setPosition(newLocation);
      this.locationMarker.setLabel({
        content: event.lnglat.lng + ',' + event.lnglat.lat,
        direction: 'top'
      });
      this.$emit('onPickerPosition', newLocation);
    }
  }
};