import api from "@/api";
export default {
  name: "UserListView",
  methods: {
    onFetchData(d) {
      for (let itm of d.list) {
        this.enableMap[itm.id] = itm.accountState == 'NORMAL';
      }
    },
    enableChange(id, val) {
      api.updateOrganizationEnableState(id, val).then(() => {
        this.enableMap[id] = val;
      });
    }
  },
  data() {
    return {
      enableMap: {},
      initQuery: {}
    };
  }
};