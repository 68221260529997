import "core-js/modules/es.array.push.js";
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-31a9f451"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "activityContainer"
};
const _hoisted_2 = {
  class: "title emTitle"
};
const _hoisted_3 = {
  class: "subTitle emSubTitle"
};
const _hoisted_4 = {
  class: "activityInfo"
};
const _hoisted_5 = {
  class: "postTime"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_col = _resolveComponent("el-col");
  const _component_el_row = _resolveComponent("el-row");
  const _component_el_divider = _resolveComponent("el-divider");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_row, {
    class: "activityRow leftCenter title"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_col, {
      span: 12
    }, {
      default: _withCtx(() => [_createElementVNode("span", null, _toDisplayString($props.data.tag) + "事件动态", 1)]),
      _: 1
    }), _createVNode(_component_el_col, {
      span: 12,
      class: "rightCenter"
    }, {
      default: _withCtx(() => [_createElementVNode("span", {
        style: {
          "color": "lightgray",
          "cursor": "pointer"
        },
        onClick: _cache[0] || (_cache[0] = $event => _ctx.$router.push('/inspect/list'))
      }, "更多")]),
      _: 1
    })]),
    _: 1
  }), _createVNode(_component_el_row, {
    class: "activityRow leftCenter"
  }, {
    default: _withCtx(() => [_createElementVNode("span", _hoisted_2, _toDisplayString($props.data.tag), 1), _createElementVNode("span", _hoisted_3, _toDisplayString($props.data.title), 1)]),
    _: 1
  }), _createVNode(_component_el_row, {
    class: "activityRow"
  }, {
    default: _withCtx(() => [_createElementVNode("span", _hoisted_4, _toDisplayString($props.data.description), 1)]),
    _: 1
  }), _createVNode(_component_el_row, {
    class: "activityRow"
  }, {
    default: _withCtx(() => [_createElementVNode("span", _hoisted_5, _toDisplayString($props.data.publishTime), 1)]),
    _: 1
  }), _createVNode(_component_el_divider, {
    style: {
      "margin": "5px 0",
      "padding": "0"
    }
  })]);
}