import AsideMenu from "@/components/AsideMenu";
import api from "@/api";
export default {
  name: "HomeView",
  components: [AsideMenu],
  data() {
    return {};
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      api.getUserInfo(localStorage.getItem('access_token')).then(ret => {
        this.$store.commit('updateUser', ret);
      });
    },
    unbindIAM() {
      api.unbindIAM().then(() => {
        this.init();
      });
    },
    logout() {
      localStorage.removeItem('access_token');
      window.location.href = window.origin + '/#/login?stop=1';
    }
  }
};